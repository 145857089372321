import axios from 'axios';
import authHeader from '../auth-header';
import { API_URL } from "../../config";

const USER_API_URL = API_URL + "/dashboard";

class DashboardService {
    adminSummary(id) {
        return axios.get(USER_API_URL + `/${id}`, { headers: authHeader() });
    }
    userSummary(id) {
        return axios.get(USER_API_URL + `/user/${id}`, { headers: authHeader() });
    }

}
export default new DashboardService();
