import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/img/hcare_imgs/scoreit_large4.svg';
import logo_light from '../../assets/img/hcare_imgs/scoreit_large_01_ligth.png';

const DefaultLogo = ({ isDark, width }) => {
  const logoStyle = { minWidth: `${width}px` };

  if (isDark) return <img id="logo" className="mr-2" src={logo_light} alt="Logo" width={width} style={logoStyle} />;

  return <img id="logo" className="mr-2" src={logo} alt="Logo" width={width} style={logoStyle} />;
};

DefaultLogo.propTypes = {
  isDark: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired
};

export default DefaultLogo;
