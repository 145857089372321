import React from 'react';
import PropTypes from 'prop-types';

const LogoText = ({ organizationName }) => {
  return (
    <div id="logotext" className="m-0 p-0">
      <div className="d-block fs-1 text-black mt-0 mb-0 pb-0">{organizationName}</div>
      <div className="d-block fs--2 text-black font-weight-light mt-0 mb-0 pt-0 pb-0" style={{ lineHeight: '50%' }}>
        powered by escoreit
      </div>
    </div>
  );
};

LogoText.propTypes = {
  organizationName: PropTypes.string.isRequired
};

export default LogoText;
