import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { numberFormatter } from '../../../helpers/utils';
import specialtyService from '../../../services/specialty/specialty.service';
import PageFilterableTable from '../PageFilterableTable';

const AssessmentStatistics = ({ isvisible }) => {
    const columns = [
        {
            dataField: 'description',
            headerClasses: 'border-0',
            text: 'Category',
            classes: 'border-0 py-2 align-middle',
            sort: true
        },
        {
            dataField: 'examsNew',
            headerClasses: 'border-0 text-right',
            text: 'New',
            classes: 'border-0 text-right',
            sort: true
        },
        // {
        //     dataField: 'examsStarted',
        //     headerClasses: 'border-0 text-right',
        //     text: 'Initiated',
        //     classes: 'border-0 text-right',
        //     sort: true
        // },
        {
            dataField: 'examsInProgress',
            headerClasses: 'border-0 text-right',
            text: 'In progress',
            classes: 'border-0 text-right',
            sort: true
        },
        {
            dataField: 'examsCompleted',
            headerClasses: 'border-0 text-right',
            text: 'Completed',
            classes: 'border-0 text-right',
            sort: true
        },
        {
            dataField: 'totalcompletionpercen',
            headerClasses: 'border-0 text-right',
            text: 'Total completion %',
            classes: 'border-0 text-right',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') {
                    return b - a;
                }
                return a - b; // desc
            }
        }
    ];

    const [statistics, setStatistics] = useState([]);
    // const [statisticsFound, setStatisticsFound] = useState([]);

    useEffect(() => {
        specialtyService.specialtyExamsStatistic()
            .then(response => {
                let responseStatistic = response.data ? response.data.map(
                    (data) => {
                        data.totalcompletionpercen = numberFormatter(data.examsCompleted * 100 / (data.examsNew + data.examsInProgress + data.examsCompleted));
                        return data;
                    }
                ) : [];
                setStatistics(responseStatistic);
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(`Try again! ${resMessage}`);
            });

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {(isvisible) &&
                // <Row style={{ alignItems: 'center' }}>
                //     <Col lg={5}>
                //         <AssessmentStatisticsChart
                //             sourcedata={statisticsFound.map((data) => { return { category: data.description, examsNew: data.examsNew, examsInProgress: data.examsInProgress, examsCompleted: data.examsCompleted } })}
                //         />
                //     </Col>
                //     <Col lg={7}>
                //         < PageFilterableTable
                //             data={statistics}
                //             columns={columns}
                //             keyField="id"
                //             title="Assessment statistics"
                //             enableNewButton={false}
                //             setSearchResult={setStatisticsFound}
                //         />
                //     </Col>
                // </Row>

                < PageFilterableTable
                    data={statistics}
                    columns={columns}
                    keyField="id"
                    title="Assessment statistics"
                    enableNewButton={false}
                    setSearchResult={setStatistics}
                    defaultSort={{ dataField: 'totalcompletionpercen', order: 'asc' }}
                />
            }
        </>
    );
};

export default AssessmentStatistics;
