import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, Label, CustomInput, FormFeedback } from 'reactstrap';
import { removeChars } from "../../../helpers/hcutils";

const CheckAnswer = (props) => {
    return <CustomInput
        type="checkbox"
        name={"answerId" + props.answerid}
        id={"answerid" + props.answerid}
        label={props.answer}
        onChange={props.onChange}
        value={props.value}
        invalid={props.invalid}
        defaultChecked={props.checked}
        className="mb-0"
    />
}

const CheckQuestion = (props) => {
    return (
        <FormGroup>
            <Label for="selectOption">
                {removeChars(props.question)}
            </Label>
            <div>
                {props.proposedanswers.map((proposedanswer, index) => <CheckAnswer
                    key={index}
                    answerid={proposedanswer.validAnswerId}
                    answer={proposedanswer.validAnswerText}
                    onChange={props.onChange}
                    value={proposedanswer.validAnswerId}
                    invalid={props.invalid}
                    checked={props.answer.includes(proposedanswer.validAnswerId)}
                />)}
            </div>
            {props.invalid && <FormFeedback style={{ display: "contents" }}>
                {props.invalidMessage ? props.invalidMessage : 'Please select an answer!'}
            </FormFeedback>}
        </FormGroup>
    )
}

export default CheckQuestion;

CheckQuestion.defaultProps = {
    question: 'How many items are?',
    proposedanswers: [],
}

CheckQuestion.propTypes = {
    question: PropTypes.string,
    proposedanswers: PropTypes.array,
}
