import React from 'react'

export const AssessmentTermsConditions = () => {
    return (
        <p>
            <strong>Terms & Conditions - Candidate Rights: (tester)</strong> Escoreit,
            LLP will only distribute with the approval of the candidate (tester) the results
            of their assessments and data to third-party select  by (tester) for employments
            purposes. Escoreit, LLP will not engage otherwise in any distribution of content,
            data and results. Escoreit, LLP will adhere to the privacy act code of conduct.
        </p>
    )
}
