import axios from 'axios';
import authHeader from './../auth-header';
import { API_URL } from "../../config";

const EXAM_API_URL = API_URL + "/exam";

class ExamService {
    all() {
        return axios.get(EXAM_API_URL, { headers: authHeader() });
    }
    create(exam) {
        return axios
            .post(EXAM_API_URL,
                exam,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    delete(id) {
        return axios.delete(EXAM_API_URL + `/${id}`, { headers: authHeader() });
    }
    update(exam) {
        return axios
            .put(EXAM_API_URL,
                exam,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    find(id) {
        return axios.get(EXAM_API_URL + `/${id}`, { headers: authHeader() });
    }
    allByUser(id) {
        return axios.get(EXAM_API_URL + `/byuser/${id}`, { headers: authHeader() });
    }
    allByUserFiltered(userId, examStatus) {
        let params = { userId, examStatus };

        return axios
            .post(EXAM_API_URL + `/byuserfiltered`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    getInfo(id) {
        return axios.get(EXAM_API_URL + `/getinfo/${id}`, { headers: authHeader() });
    }
    limits(id) {
        return axios.get(EXAM_API_URL + `/limitquestion/${id}`, { headers: authHeader() });
    }
    length(id) {
        return axios.get(EXAM_API_URL + `/countquestion/${id}`, { headers: authHeader() });
    }
    byBundle(id) {
        return axios.get(EXAM_API_URL + `/bybundle/${id}`, { headers: authHeader() });
    }
    start(examId, userId, clinicianAssessmentId) {
        let startParams = { examId, userId, clinicianAssessmentId };

        return axios
            .post(EXAM_API_URL + `/start`,
                startParams,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    complete(examId, userId, clinicianAssessmentId) {
        let params = { examId, userId, clinicianAssessmentId };

        return axios
            .post(EXAM_API_URL + `/complete`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    nextQuestion(examId, userId, questionId) {
        let params = { examId, userId, questionId };

        return axios
            .post(EXAM_API_URL + `/nextquestion`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    updateQuestion(examId, userId, questionId, answers) {
        let params = { examId, userId, questionId, answers };

        return axios
            .post(EXAM_API_URL + `/updatequestion`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    updateQuestionChecklist(examId, userId, questionId, answers) {
        let params = { examId, userId, questionId, answers };

        return axios
            .post(EXAM_API_URL + `/updatequestionchecklist`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    score(examId, userId) {
        let params = { examId, userId };

        return axios
            .post(EXAM_API_URL + `/scorebyuser`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    scoreByUserChecklist(examId, userId) {
        let params = { examId, userId };

        return axios
            .post(EXAM_API_URL + `/scorebyuser_checklist`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    byUserSpecialty(userId, specialityId) {
        let params = { userId, specialityId };

        return axios
            .post(EXAM_API_URL + `/byuserspecialty`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    byProfDesignationType(userId, profDesignationTypeId) {
        let params = { userId, profDesignationTypeId };

        return axios
            .post(EXAM_API_URL + `/byProfDesignationType`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    byProfDesignationTypeOriginal(userId, profDesignationTypeId) {
        let params = { userId, profDesignationTypeId };

        return axios
            .post(EXAM_API_URL + `/byProfDesignationTypeOriginal`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    reportExam(examId, userId, clinicianAssessmentId) {
        let params = { examId, userId, clinicianAssessmentId };

        return axios
            .post(EXAM_API_URL + `/reportexam`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    reportChecklist(examId, userId, clinicianAssessmentId) {
        let params = { examId, userId, clinicianAssessmentId };

        return axios
            .post(EXAM_API_URL + `/reportchecklist`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }

    orgReporting(organizationId, intervalenum) {
        let params = { organizationId, intervalenum };

        return axios
            .post(EXAM_API_URL + `/orgreporting`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }

}
export default new ExamService();
