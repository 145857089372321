import axios from 'axios';
import authHeader from './auth-header';
import { API_URL } from "../config";

const USER_API_URL = API_URL + "/user";

class UserService {
    getPublicContent() {
        return axios.get(USER_API_URL + 'all');
    }

    getUserBoard() {
        return axios.get(USER_API_URL + 'user', { headers: authHeader() });
    }

    getAdminBoard() {
        return axios.get(USER_API_URL + 'admin', { headers: authHeader() });
    }

    forgotPassword(email) {
        return axios
            .post(USER_API_URL + "/forgot-password",
                { email },
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }

    forgotUsername(email) {
        return axios
            .post(USER_API_URL + "/forgot-username",
                { email },
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }

    passwordReset(token, password) {
        return axios
            .put(USER_API_URL + '/reset-password',
                {
                    token,
                    password
                }
            )
            .then(response => {
                return response.data;
            });
    }
    all() {
        return axios.get(USER_API_URL, { headers: authHeader() });
    }
    clinicians() {
        return axios.get(USER_API_URL + "/testers", { headers: authHeader() });
    }
    testersDisabled() {
        return axios.get(USER_API_URL + "/testers_disabled", { headers: authHeader() });
    }
    create(user) {
        user.phoneNumber = user.phoneNumber ? user.phoneNumber : null;
        user.isRegisterClinician = false;
        return axios
            .post(USER_API_URL,
                user,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    createClinician(user) {
        user.phoneNumber = user.phoneNumber ? user.phoneNumber : null;
        user.isRegisterClinician = false;
        return axios
            .post(USER_API_URL + '/tester',
                user,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    delete(id) {
        return axios.delete(USER_API_URL + `/${id}`, { headers: authHeader() });
    }
    update(user) {
        return axios
            .put(USER_API_URL,
                user,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    updateClinician(user) {
        return axios
            .put(USER_API_URL + '/tester',
                user,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    find(id) {
        return axios.get(USER_API_URL + `/${id}`, { headers: authHeader() });
    }
    findClinician(id) {
        return axios.get(USER_API_URL + `/tester/${id}`, { headers: authHeader() });
    }
    byDepartment(departmentId) {
        return axios.get(USER_API_URL + `/bydepartment/${departmentId}`, { headers: authHeader() });
    }
    cliniciansByDepartment(departmentId) {
        return axios.get(USER_API_URL + `/testers/bydepartment/${departmentId}`, { headers: authHeader() });
    }

    assignBundles(bundles) {
        return axios
            .post(USER_API_URL + '/assignbundles',
                bundles,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    updatePassword(oldPassword, newPassword) {
        let params = { oldPassword, newPassword };
        return axios
            .put(USER_API_URL + '/update-password',
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    assignExam(userId, isNotify, exams) {
        let params = { userId, isNotify, exams };

        return axios
            .post(USER_API_URL + '/assignexam',
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    userModel() {
        return axios.get(USER_API_URL + "/usermodel", { headers: authHeader() });
    }

    // Executed by any admin. Reset password to userId
    password_Reset(userId, password) {
        let params = { userId, password };
        return axios
            .put(USER_API_URL + '/password-reset',
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }

    updateIsDisabled(id) {
        let url = USER_API_URL + `/update-isdisabled/${id}`;
        return axios
            .put(url,
                { id },
                { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }
}

export default new UserService();
