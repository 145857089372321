import React, { Fragment, useEffect, useState } from 'react';
import CardSummary from '../../../components/dashboard/CardSummary';
import { toast } from 'react-toastify';
import DashboardService from '../../../services/user/dashboard.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const UserCardSummary = (props) => {
    const { status, updateStatus, listhaschange, setListHasChange, userid, refresh, setRefresh } = props;
    // State
    const [userSummary, setUserSummary] = useState(null);

    const getSummary = (id) => {
        DashboardService.userSummary(id)
            .then(response => {

                setUserSummary(response.data);
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(`${resMessage}`);
            });
    }

    useEffect(() => {
        if (listhaschange || refresh) {
            getSummary(userid);
            setListHasChange(false)
            if (refresh) {
                setRefresh(false);
            }
        }
    }, [listhaschange, userid, setListHasChange, refresh, setRefresh]);

    useEffect(() => {
        getSummary(userid);

    }, [userid]);

    return (
        <Fragment>
            <div className="card-deck">
                <div style={{ "flex": "auto", "cursor": "pointer" }} onClick={() => updateStatus(0)}>
                    <CardSummary title="Total" color="info">
                        <FontAwesomeIcon icon="file-alt" transform="grow-2 up-12" className="outside" />
                        {userSummary && userSummary.countTotal >= 0 ? userSummary.countTotal : '0'}
                        {status === 0 && <FontAwesomeIcon icon="check" transform="grow-2 up-12" className="outside-right fa-xs" />}
                    </CardSummary>
                </div>
                <div style={{ "flex": "auto", "cursor": "pointer" }} onClick={() => updateStatus(1)}>
                    <CardSummary title="New" color="danger">
                        <FontAwesomeIcon icon='external-link-alt' transform="grow-2 up-12" className="outside" />
                        {userSummary && userSummary.countNew >= 0 ? userSummary.countNew : '0'}
                        {status === 1 && <FontAwesomeIcon icon="check" transform="grow-2 up-12" className="outside-right fa-xs" />}
                    </CardSummary>
                </div>
                <div style={{ "flex": "auto", "cursor": "pointer" }} onClick={() => updateStatus(2)}>
                    <CardSummary title="In progress" color="warning">
                        <FontAwesomeIcon icon="edit" transform="grow-2 up-12" className="outside" />
                        {userSummary && userSummary.countInprogress >= 0 ? userSummary.countInprogress : '0'}
                        {status === 2 && <FontAwesomeIcon icon="check" transform="grow-2 up-12" className="outside-right fa-xs" />}
                    </CardSummary>
                </div>
                <div style={{ "flex": "auto", "cursor": "pointer" }} onClick={() => updateStatus(3)}>
                    <CardSummary title="Completed" color="success">
                        <FontAwesomeIcon icon={['fas', 'check-square']} transform="grow-2 up-12" className="text-success outside" />
                        {userSummary && userSummary.countCompleted >= 0 ? <span className="text-success">{userSummary.countCompleted}</span> : '0'}
                        {status === 3 && <FontAwesomeIcon icon="check" transform="grow-2 up-12" className="text-success outside-right fa-xs" />}
                    </CardSummary>
                </div>
            </div>
        </Fragment >
    );
};

UserCardSummary.propTypes = {
    refresh: PropTypes.bool
};

UserCardSummary.defaultProps = {
    refresh: false
};
export default UserCardSummary;
