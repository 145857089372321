import axios from 'axios';
import authHeader from './../auth-header';
import { API_URL } from "../../config";

const ORGANIZATION_API_URL = API_URL + "/organization";

class OrganizationService {
    all() {
        return axios.get(ORGANIZATION_API_URL, { headers: authHeader() });
    }
    create(organization) {
        return axios
            .post(ORGANIZATION_API_URL,
                organization,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    delete(id) {
        return axios.delete(ORGANIZATION_API_URL + `/${id}`, { headers: authHeader() });
    }
    update(organization) {
        return axios
            .put(ORGANIZATION_API_URL,
                organization,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    find(id) {
        return axios.get(ORGANIZATION_API_URL + `/${id}`, { headers: authHeader() });
    }
    allByUser(id) {
        return axios.get(ORGANIZATION_API_URL + `/byuser/${id}`, { headers: authHeader() });
    }
    organizationModel() {
        return axios.get(ORGANIZATION_API_URL + "/organizationmodel", { headers: authHeader() });
    }

    // Get all organization attibutes 
    //id: Organization id
    attributes(id) {
        return axios.get(ORGANIZATION_API_URL + `/attributes/${id}`, { headers: authHeader() });
    }
    // Get all organization exam
    urlencryptedlist(id) {
        return axios.get(ORGANIZATION_API_URL + `/urlencrypted/${id}`, { headers: authHeader() });
    }

    updateAttribute(organizationId, attributeName, attributeValue) {
        let params = {
            organizationId,
            attributeName,
            attributeValue
        }

        return axios
            .put(ORGANIZATION_API_URL + '/attributes',
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }

    updateIsDisabled(id) {
        let url = ORGANIZATION_API_URL + `/update-isdisabled/${id}`;
        return axios
            .put(url,
                { id },
                { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }

    getUrlEncryptedByUser(organizationId, examName, email) {
        let params = { organizationId, examName, email };
        return axios
            .post(ORGANIZATION_API_URL + `/urlencryptedbyuser`,
                params,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data[0];
            });
    }
    // id: current user id
    testers_completed_from_last_login(id) {
        return axios.get(ORGANIZATION_API_URL + `/testers_completed_from_last_login/${id}`, { headers: authHeader() });
    }
}

export default new OrganizationService();
