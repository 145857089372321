import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import specialtyService from '../../../services/specialty/specialty.service';
import { SpecialtyStatisticsApexChart } from './SpecialtyStatisticsApexChart';

const SpecialtyStatistics = ({ isvisible }) => {
    const [statisticsFound, setStatisticsFound] = useState([]);
    const [colorpalette, setColorpalette] = useState(null);

    useEffect(() => {
        specialtyService.statistic()
            .then(response => {
                let source = response.data ? response.data : [];

                setStatisticsFound(source);

                const randomNum = () => Math.floor(Math.random() * 255);

                const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`;
                setColorpalette(source.map(s => randomRGB()));
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(`Try again! ${resMessage}`);
            });

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {(isvisible) &&
                <Fragment>
                    {colorpalette &&
                        <SpecialtyStatisticsApexChart
                            sourcelabel={statisticsFound.map((data) => data.description)}
                            sourcedata={statisticsFound.map((data) => data.numberClinicians)}
                            colorpalette={colorpalette}
                        />
                    }
                </Fragment>
            }
        </>
    );
};

export default SpecialtyStatistics;
