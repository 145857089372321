import React, { Fragment, createRef, useEffect } from "react";
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import ButtonIcon from '../../components/common/ButtonIcon';
import './headerstyle.css';

import BackButton from "./BackButton";

const { SearchBar, ClearSearchButton } = Search;

const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => {
    return <div className="custom-control custom-checkbox">
        <input
            className="custom-control-input"
            {...rest}
            onChange={() => { }}
            ref={input => {
                if (input) input.indeterminate = indeterminate;
            }}
        />
        <label className="custom-control-label" />
    </div>
};
export default class PageFilterableTable extends React.Component {
    table = createRef();
    selectRow = onSelect => ({
        mode: 'checkbox',
        columnClasses: 'py-2 align-middle',
        clickToSelect: false,
        selectionHeaderRenderer: ({ mode, ...rest }) => <SelectRowInput type="checkbox" {...rest} />,
        selectionRenderer: ({ mode, ...rest }) => <SelectRowInput type={mode} {...rest} />,
        headerColumnStyle: { border: 0, verticalAlign: 'middle' },
        selectColumnStyle: { border: 0, verticalAlign: 'middle' },
        onSelect: onSelect,
        onSelectAll: onSelect
    });
    onSelect = () => {
        setImmediate(() => {
            this.setIsSelected(!!this.table.current.selectionContext.selected.length, this.table.current.selectionContext.selected);
        });
    };
    constructor(props) {
        super(props);
        this.handle_OnNewClick = this.handle_OnNewClick.bind(this);
        this.setIsSelected = this.setIsSelected.bind(this);
    }
    handle_OnNewClick() {
        this.props.handle_OnNewClick()
    }
    setIsSelected(isSelected, selectedRows) {
        this.props.setIsSelected(isSelected, selectedRows);
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.props.data.length !== nextProps.data.length) {
    //         return true;
    //     }
    //     return false;
    // }
    render() {
        const paginationOption = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            totalSize: this.props.data ? this.props.data.length : 0,
            sizePerPage: this.props.sizePerPage,
            hidePageListOnlyOnePage: true
        };

        const tableOption = {
            title: this.props.title,
            keyField: this.props.keyField,
            columns: this.props.columns,
            data: this.props.data,
            setSearchResult: this.props.setSearchResult,
            useSelectRow: this.props.useSelectRow,
            enableNewButton: this.props.enableNewButton,
            ownerKeyValue: this.props.ownerKeyValue,
            defaultSearch: this.props.defaultSearch,
            leftActionButtons: this.props.leftActionButtons,
            rightActionButtons: this.props.rightActionButtons,
            setSearchText: this.props.setSearchText,
            setCompanyFilter: this.props.setCompanyFilter,
        };

        const Maintoolkit = ({ toolkitprops, paginationTableProps }) => {
            // tableOption.setSearchResult(newResult);
            useEffect(() => {
                if (tableOption.defaultSearch !== toolkitprops.searchProps.searchText)
                    if (tableOption.setCompanyFilter) {
                        tableOption.setCompanyFilter(toolkitprops.searchProps.searchText);
                    }
            }, [toolkitprops.searchProps.searchText])

            return (
                <Fragment>
                    <FalconCardHeader title={tableOption.title} light={false}>
                        <Row id="actionrow">
                            {tableOption.data.length > 0 &&
                                <Fragment>
                                    {tableOption.leftActionButtons && tableOption.leftActionButtons}
                                    <div id="spec_searchbar_local">
                                        <SearchBar className="rounded-pill search-input" {...toolkitprops.searchProps} />
                                    </div>
                                    <ClearSearchButton tableId="agencytable" {...toolkitprops.searchProps} className="btn-falcon-default d-inline ml-2" color="falcon-default" />
                                </Fragment>
                            }
                            {tableOption.ownerKeyValue !== 0 && <BackButton className="ml-2" />}
                            {tableOption.enableNewButton === true ?
                                (
                                    <ButtonIcon className="d-inline ml-2" icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm" onClick={this.handle_OnNewClick}>
                                        New
                                    </ButtonIcon>
                                ) : (
                                    ''
                                )
                            }
                            {tableOption.rightActionButtons && tableOption.rightActionButtons}
                        </Row>
                    </FalconCardHeader>

                    {tableOption.useSelectRow ?
                        (
                            <BootstrapTable
                                striped
                                hover
                                ref={this.table}
                                keyField={tableOption.keyField}
                                selectRow={this.selectRow(this.onSelect)}
                                noDataIndication={'no results found'}
                                bordered={false}
                                classes="table-sm fs--1 border-bottom border-200 mb-0"
                                rowClasses="btn-reveal-trigger border-top border-200"
                                headerClasses="bg-200 text-900 border-y border-200"
                                rowEvents={this.props.rowEvents}
                                sort={this.props.defaultSort}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                            />
                        ) : (
                            <BootstrapTable
                                striped
                                hover
                                ref={this.table}
                                keyField={tableOption.keyField}
                                noDataIndication={'no results found'}
                                bordered={false}
                                classes="table-sm fs--1 border-bottom border-200 mb-0"
                                rowClasses="btn-reveal-trigger border-top border-200"
                                headerClasses="bg-200 text-900 border-y border-200"
                                rowEvents={this.props.rowEvents}
                                sort={this.props.defaultSort}
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                            />
                        )
                    }
                </Fragment>
            )
        }

        return (
            <Fragment>
                <PaginationProvider
                    pagination={
                        paginationFactory(paginationOption)
                    }
                >
                    {
                        ({ paginationProps, paginationTableProps }) => {

                            const afterSearch = (newResult) => {
                                tableOption.setSearchResult(newResult);
                            };

                            return (
                                <Fragment>
                                    <ToolkitProvider
                                        keyField={tableOption.keyField}
                                        columns={tableOption.columns}
                                        data={tableOption.data}
                                        // search={{ afterSearch }}
                                        search={{
                                            afterSearch: afterSearch,
                                            defaultSearch: tableOption.defaultSearch
                                        }}
                                    >
                                        {
                                            toolkitprops => (
                                                <Maintoolkit toolkitprops={toolkitprops} paginationTableProps={paginationTableProps} />
                                            )
                                        }
                                    </ToolkitProvider>
                                    <Row noGutters className="px-1 py-3 flex-center">
                                        <Col xs="auto">
                                            <PaginationListStandalone {...paginationProps} />
                                        </Col>
                                    </Row>
                                </Fragment>
                            )
                        }
                    }
                </PaginationProvider>
            </Fragment >
        );
    }
}

PageFilterableTable.defaultProps = {
    title: '',
    ownerKeyValue: 0,
    useSelectRow: false,
    enableNewButton: true,
    sizePerPage: 10,
    setSearchResult: () => { },
    defaultSort: null,
    rowEvents: null,
    defaultSearch: '',
    setSearchText: () => { },
};

PageFilterableTable.propTypes = {
    title: PropTypes.string,
    datalist: PropTypes.array,
    columns: PropTypes.array,
    handle_OnNewClick: PropTypes.func,
    setIsSelected: PropTypes.func,
    keyField: PropTypes.string,
    ownerKeyValue: PropTypes.number,
    useSelectRow: PropTypes.bool,
    enableNewButton: PropTypes.bool,
    sizePerPage: PropTypes.number,
    setSearchResult: PropTypes.func,
    defaultSort: PropTypes.object,
    rowEvents: PropTypes.object,
    defaultSearch: PropTypes.string,
    setSearchText: PropTypes.func,
};
