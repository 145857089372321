import React from 'react';
import { useHistory } from "react-router-dom";

import ButtonIcon from '../../components/common/ButtonIcon';

const BackButton = ({ ...rest }) => {

    const history = useHistory();

    return (
        < ButtonIcon {...rest} icon="arrow-left" transform="shrink-3 down-2" color="falcon-default" size="sm" onClick={() => history.goBack()} >
            Go Back
        </ButtonIcon >
    )
}
export default BackButton;