import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../hcare/components/dashboard/Dashboard';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import { getPageName } from '../helpers/utils';
import fileService from '../services/file/file.service';
import { toast } from 'react-toastify';
import userService from '../services/user.service';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const blobToBase64 = (blob, callback) => {
  var reader = new FileReader();
  reader.onload = function() {
    var dataUrl = reader.result;
    callback(dataUrl);
  };
  reader.readAsDataURL(blob);
};

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle, isDark, currentuser } = useContext(AppContext);

  const isKanban = getPageName('kanban');
  const [currentuserdata, setCurrentUserData] = useState(null);
  const [organizationLogo, setOrganizationLogo] = useState(null);
  const [isOrganizationLogoLoaded, setIsOrganizationLogoLoaded] = useState(false);
  const [isCurrentUserDataLoaded, setIsCurrentUserDataLoaded] = useState(false);

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const getOrganizationLogo = (id, isDark) => {
      fileService
        .getOrganization_logo(id, isDark)
        .then(response => {
          if (response.data && response.data.size > 0) {
            const newBlob = new Blob([response.data], { type: 'image/jpeg' });
            blobToBase64(newBlob, pbase64 => {
              let a = [{ base64: pbase64 }];
              setOrganizationLogo(a);
              setIsOrganizationLogoLoaded(true);
            });
          } else {
            setIsOrganizationLogoLoaded(true);
          }
        })
        .catch(error => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

          toast.error(`Try again! ${resMessage}`);
        });
    };

    if (currentuserdata && currentuserdata.department) {
      getOrganizationLogo(currentuserdata.department.organization.organizationId, isDark);
    } else {
      setIsOrganizationLogoLoaded(true);
    }
  }, [currentuserdata, isDark]);

  useEffect(() => {
    const getUser = id => {
      userService
        .find(id)
        .then(response => {
          setCurrentUserData(response.data);
          setIsCurrentUserDataLoaded(true);
        })
        .catch(error => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

          toast.error(`Try again! ${resMessage}`);
        });
    };

    if (currentuser && !isCurrentUserDataLoaded) {
      getUser(currentuser.id);
    }
  }, [currentuser, isCurrentUserDataLoaded]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && (
        <NavbarVertical
          isKanban={isKanban}
          navbarStyle={navbarStyle}
          currentuserdata={currentuserdata}
          organizationLogo={organizationLogo}
        />
      )}
      <div className="content" style={{ opacity: 0.99 }}>
        <NavbarTop currentuserdata={currentuserdata} organizationLogo={organizationLogo} />
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <DashboardRoutes />
        </Switch>
        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
