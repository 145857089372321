import React, { Fragment, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import AppContext from '../../../context/Context';

import { appName, createdBy } from '../../../config';
import ExamList from "../common/examList";

import UserCardSummary from "../common/userCardSummary";
import AdminCardSummary from "../common/adminCardSummary";


const Dashboard = () => {
  const { currentuser } = useContext(AppContext);
  const [examStatus, setExamStatus] = useState(0);
  const [examListHasChange, setExamListHasChange] = useState(false);

  useEffect(() => {
    toast(
      <Fragment>
        Welcome to <strong>{appName}!</strong><br />
        {createdBy}
      </Fragment>
    );
  }, []);

  return (
    <Fragment>
      {currentuser.roles[0] === "user" &&
        <Fragment>
          <UserCardSummary
            status={examStatus}
            updateStatus={setExamStatus}
            listhaschange={examListHasChange}
            setListHasChange={setExamListHasChange}
            userid={currentuser.id}
          />
          <ExamList
            status={examStatus}
            updateStatus={setExamStatus}
            setListHasChange={setExamListHasChange}
          />
        </Fragment>
      }
      {currentuser.roles[0] !== "user" &&
        <Fragment>
          <AdminCardSummary />
        </Fragment>
      }
    </Fragment>
  );
};

export default Dashboard;
