export function ucFirst(str) {

    return str &&
        str
            .toLowerCase()
            .split(' ')
            .map(function (Word) {
                return Word ? Word[0].toUpperCase() + Word.substr(1) : '';
            })
            .join(' ');
}

export function hcround(num, precision = 2) {
    let m = Number((Math.abs(num) * 100).toPrecision(15));
    let res = Math.round(m) / 100 * Math.sign(num);
    return res.toFixed(precision);
}

export const numberToLetter = (number) => {
    switch (number) {
        case '1':
            return 'one';
        case '2':
            return 'two';
        case '3':
            return 'three';
        case '4':
            return 'four';
        case '5':
            return 'five';
        case '6':
            return 'six';
        case '7':
            return 'seven';
        case '8':
            return 'eight';
        case '9':
            return 'nine';

        default:
            return 'none';
    }
}

export function valueToPercent(value, max) {
    return (value * 100) / max
}

export function stringToDate(strDate) {
    let ms = Date.parse(strDate);
    let fecha = new Date(ms);
    return fecha.toLocaleString();
}

export function removeChars(strtoclean) {
    return strtoclean.includes('�') ? strtoclean.replace('�', '') : strtoclean;
}

export function beautyPipeline(str) {
    return str.replaceAll('|', '\n\n');
}

export function beautyPipelineCeiling(str) {
    str = str.replaceAll('|', '\n\n\n');
    str = str.replaceAll('^', '\n\n');

    return str;
}

export function getCurrentDate() {

    let newDate = new Date()
    let toLocaleString = stringToDate(newDate);
    return toLocaleString;
}