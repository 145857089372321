import React from 'react';

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

const HcareModal = (args) => {
    const { title, children, isOpen, closeModal, submitbtn = undefined, enablebtn = true, ...argsrest } = args;
    const { customStyle, ...rest } = argsrest
    return (
        <Modal isOpen={isOpen} toggle={() => closeModal} style={customStyle} {...rest}>
            <ModalHeader
                close={<button className="close float-none" type="button" aria-label="Close" onClick={closeModal}>
                    <span aria-hidden="true">×</span>
                </button>}
            >
                {title}
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {
                enablebtn &&
                <ModalFooter className='d-flex'>
                    <Button onClick={closeModal}>
                        Close
                    </Button>
                    {/* <Button color="primary" onClick={() => { onsubmit() }}>{actiontitle}</Button> */}
                    {submitbtn ? submitbtn() : ''}
                </ModalFooter>
            }
        </Modal >
    );
};
export default HcareModal;
