import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { getItemFromStore, setItemToStore } from './helpers/utils';

const App = () => {

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }

      let currentuser = getItemFromStore('currentuser', null);
      if (currentuser) {
        setItemToStore('currentuser', null);
      }
      setItemToStore('reportCompanyFilter', '');
      setItemToStore('reportPeriodType', 'day');
      setItemToStore('reportSelectedOrganizationId', 0);
      setItemToStore('reportSelectedOrganizationName', '');
      setItemToStore('reportExamResultFilter', '');
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
