import axios from 'axios';
import authHeader from './../auth-header';
import { API_URL } from '../../config';

const FILE_API_URL = API_URL + '/files';

class FileService {
  all() {
    return axios.get(FILE_API_URL, { headers: authHeader() });
  }

  allByOrganization(id) {
    return axios.get(FILE_API_URL + `/organization/${id}`, { headers: authHeader() });
  }

  getFile(file) {
    return axios.get(FILE_API_URL + `/${file}`, { headers: authHeader(), responseType: 'blob' });
  }

  getOrganization_logo(id, isDark) {
    let params = {
      organizationId: id,
      isDark: isDark ? true : false
    };

    return axios.post(FILE_API_URL + `/organization_logo`, params, {
      headers: authHeader(),
      responseType: 'blob'
    });
  }

  upload(file, organizationId) {
    let params = new FormData();
    params.append('file', file);
    params.append('organizationId', organizationId);

    return axios.post(FILE_API_URL + `/upload`, params, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  upload_logo(file, organizationId, isDark) {
    let params = new FormData();
    params.append('file', file);
    params.append('organizationId', organizationId);
    params.append('isDark', isDark);

    return axios.post(FILE_API_URL + `/upload_logo`, params, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }
}
export default new FileService();
