import React from 'react'
import Chart from "react-apexcharts";
import { hcround } from '../../../helpers/hcutils';

const SpecialtyStatisticsLegend = ({ sourcelabel, colorpalette }) => {
    return (
        <div style={{
            WebkitColumns: 3,
            MozColumns: 3,
            columns: 3,
            WebkitColumnWidth: '120px',
            MozColumnWidth: '120px',
            columnWidth: '120px'
        }} >
            {sourcelabel && sourcelabel.map(
                (label, index) =>
                    <div key={index} style={{ minWidth: '120px', maxWidth: '140px' }}>
                        <div
                            style={
                                {
                                    float: 'left',
                                    height: '10px',
                                    width: '10px',
                                    marginRight: '5px',
                                    marginTop: '3px',
                                    border: '1px solid black',
                                    clear: 'both',
                                    color: colorpalette[index],
                                    backgroundColor: colorpalette[index],
                                    borderColor: colorpalette[index],
                                }
                            }>
                        </div>
                        <div className='ml-2'
                            style={
                                {
                                    fontSize: '13px',
                                    fontWeight: '400',
                                    fontFamily: 'Helvetica, Arial, sans-serif'
                                }
                            }>
                            {label}
                        </div>
                    </div>
            )}
        </div>
    )
}

export const SpecialtyStatisticsApexChart = ({ sourcelabel, sourcedata, colorpalette }) => {
    const series = sourcedata;

    const options = {
        chart: {
            type: 'pie',
        },
        labels: sourcelabel,
        colors: colorpalette,
        legend: {
            show: false
        },
        tooltip: {
            enabled: true,
        },
        stroke: {
            show: false,
        },
        dataLabels: {
            enabled: false,
            formatter: function (val, opts) {
                return hcround(opts.w.config.series[opts.seriesIndex], 0);
            },
            style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: '400',
                colors: ['rgb(255,255,255)']
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },
        title: {
            text: 'Specialty statistics',
            align: 'center',
            margin: 25,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '1.2rem',
                fontWeight: 'bold',
                fontFamily: 'Arial',
                color: '#263238'
            },
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center flex-wrap mb-3">
            <div className="p-2">
                < Chart options={options} series={series} type="pie" style={{ width: '350px', minWidth: '200px', /*height: '250px',*/ margin: 'auto', marginTop: '2em' }} />
            </div>
            <div className="p-2">
                <SpecialtyStatisticsLegend sourcelabel={sourcelabel} colorpalette={colorpalette} />
            </div >
        </div >
    )
} 
