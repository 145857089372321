import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Card, CardBody, Badge, Button, FormGroup, Input, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '../../../components/common/Loader';

import AppContext from '../../../context/Context';
import PageFilterableTable from '../PageFilterableTable';
import ExamDialog from '../question/examDialog';

import ExamService from '../../../services/exam/exam.service';
import { useHistory } from 'react-router-dom';
import userService from '../../../services/user.service';
import { useHcareModal } from '../../hooks/useHcareModal';
import HcareModal from './modal/HcareModal';
import { AssessmentTermsConditions } from './AssessmentTermsConditions';
import './styles.css';

function ExamResult({ row, onClick }) {
  return (
    <Fragment>
      <Badge
        color={row.examScore.passed ? 'success' : 'danger'}
        className="fs--1 w-75"
        pill
        style={{ cursor: 'pointer' }}
        onClick={e => onClick(e, row)}
      >
         {row.examScore.clinicScore + ' '} {row.examScore.passed ? 'Pass' : 'Fail'}
      </Badge>
    </Fragment>
  );
}

function CheckListResult({ row, onClick }) {
  return (
    <Fragment>
      <Badge color={'success'} className="fs--1 w-75" pill style={{ cursor: 'pointer' }} onClick={e => onClick(e, row)}>
        {row.examScore ? ((row.examScore.frequency + row.examScore.proficiency) / 2).toFixed(1) : 'no data'
        // row.examScore ? "Frequency: " + row.examScore.frequency + " Proficiency: " + row.examScore.proficiency : "no data"
        }
        {/* Completed */}
      </Badge>
    </Fragment>
  );
}

const ExamList = props => {
  const { setListHasChange, status } = props;

  const { currentuser } = useContext(AppContext);
  const [isLoadedExamList, setIsLoadedExamList] = useState(false);
  const [examList, setExamList] = useState(null);
  const [organizationName, setOrganizationName] = useState('');

  //Exam state
  const [showExamDialog, setShowExamDialog] = useState(false);
  const [currentExam, setCurrentExam] = useState(null);

  //Assessment terms
  const [isOpenTerms, openModalTerms, closeModalTerms] = useHcareModal(false);
  const [accept, setAccept] = useState(false);

  let history = useHistory();

  const acceptHandle_OnChange = e => {
    if (e) {
      if (e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
        setAccept(e.target.checked ? true : false);
      }
    }
  };

  const acceptRenderSubmitBtn = () => {
    return (
      <Fragment>
        <Button
          color="primary"
          disabled={!accept}
          onClick={() => {
            if (accept) {
              setShowExamDialog(!showExamDialog);
            }
            closeModalTerms();
          }}
        >
          Accept
        </Button>
      </Fragment>
    );
  };

  const handle_OnClickCloseButton = () => {
    setAccept(false);
    closeModalTerms();
  };

  const handle_OnClick = (e, exam) => {
    e.preventDefault();
    if (exam.status.toLowerCase() === 'new') {
      openModalTerms();
    } else {
      setShowExamDialog(!showExamDialog);
    }

    setCurrentExam(() => {
      return exam;
    }, exam);
  };

  const handle_OnShowReport = (e, row) => {
    e.preventDefault();
    const examType = row.examType.toLowerCase();
    history.push(`/exam/report/${row.examId}/${currentuser.id}/${row.clinicianAssessmentId}/${examType}`);
  };

  // const handle_OnShowReport = (e, row) => {
  //   e.preventDefault();
  //   let key = row.examType.toLowerCase();
  //   let examType = '';
  //   switch (key) {
  //     case 'exam':
  //       examType = 'other';
  //       break;
  //     case 'checklist':
  //       examType = 'checklist';
  //       break;
  //     default:
  //       examType = '';
  //   }
  //   history.push(`/exam/report/${row.examId}/${currentuser.id}/${row.clinicianAssessmentId}/${examType}`);
  // };

  //PageFilterableTable config
  const stateFormatter = (dataField, row) => {
    if (row)
      switch (row.status.toLowerCase()) {
        case 'new':
          return (
            <Badge color={'soft-primary'} className="fs--1" pill>
              New
            </Badge>
          );
        // break;
        case 'initiated':
          return (
            <Badge color={'soft-info'} className="fs--1" pill>
              Initiated
            </Badge>
          );
        // break;
        case 'in progress':
          return (
            <Badge color={'soft-warning'} className="fs--1" pill>
              In progress
            </Badge>
          );
        // break;
        case 'complete':
          return (
            <Badge color={'soft-success'} className="fs--1" pill>
              Completed
            </Badge>
          );
        // break;
        default:
          return (
            <Badge color={'soft-danger'} className="fs--1" pill>
              Undefined
            </Badge>
          );
        // break;
      }
  };

  const actionFormatter = (dataField, row) => {
    let disabled = row.examQuestionCount === null || row.examQuestionCount <= 1 ? true : false;

    if (row)
      switch (row.status.toLowerCase()) {
        case 'new':
          return (
            <Button
              color={`falcon-${disabled ? 'dark' : 'success'}`}
              disabled={disabled}
              onClick={e => handle_OnClick(e, row)}
            >
              <FontAwesomeIcon icon="play" />
            </Button>
          );
        // break;
        case 'initiated':
          return (
            <Button color={`falcon-${disabled ? 'dark' : 'success'}`} disabled={disabled}>
              <FontAwesomeIcon icon="play" />
            </Button>
          );
        case 'in progress':
          return (
            <Button
              color={`falcon-${disabled ? 'dark' : 'success'}`}
              disabled={disabled}
              onClick={e => handle_OnClick(e, row)}
            >
              <FontAwesomeIcon icon="play" />
            </Button>
          );
        case 'complete':
          return (
            <Fragment>
              {row.examType === 'Exam' && <ExamResult row={row} onClick={handle_OnShowReport} />}
              {row.examType === 'Checklist' && <CheckListResult row={row} onClick={handle_OnShowReport} />}
            </Fragment>
          );
        // case 'ended':
        //     return (
        //         <Button color={'falcon-success'} className="mr-2" >
        //             <FontAwesomeIcon icon="pause" />
        //         </Button>
        //     )
        default:
          return <Badge color={'soft-danger'} className="mr-2" pill />;
        // break;
      }
  };

  const viewFormatter = (dataField, row) => {
    if (row && row.status.toLowerCase() === 'complete')
      return (
        <Badge
          color={'info'}
          className="ml-2 fs--1"
          pill
          style={{ cursor: 'pointer' }}
          onClick={e => handle_OnShowReport(e, row)}
        >
          View
        </Badge>
      );
  };

  const columns = [
    {
      dataField: 'examName',
      headerClasses: 'border-0',
      text: 'Description',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'status',
      headerClasses: 'border-0',
      text: 'Status',
      classes: 'border-0 py-2 align-middle',
      formatter: stateFormatter,
      align: 'center',
      headerAlign: 'center',
      sort: true
    },
    {
      dataField: 'examscore',
      headerClasses: 'border-0 ',
      text: 'Score %',
      classes: 'border-0 py-2 align-middle font-weight-bold',
      align: 'center',
      headerAlign: 'center',
      formatter: actionFormatter
      // headerClasses: 'text-right',
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-right',
      text: 'Report',
      classes: 'border-0 py-2 align-middle text-right font-weight-bold',
      formatter: viewFormatter
    }
  ];
  //PageFilterableTable config

  //ExamDialog
  function setExamDialogVisible(value) {
    setShowExamDialog(value);
  }

  useEffect(() => {
    if (!showExamDialog) {
      setIsLoadedExamList(false);
      getExamListFiltered(currentuser.id, status);
      setListHasChange(true);
    }
  }, [showExamDialog, setListHasChange, status, currentuser]);

  const getExamListFiltered = (userid, examstatus) => {
    Promise.all([ExamService.allByUserFiltered(userid, examstatus), userService.find(userid)])
      .then(response => {
        setExamList(response[0]);
        setOrganizationName(response[1].data.department.organization.organizationName);
        setIsLoadedExamList(true);
      })
      .catch(error => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        toast.error(`${resMessage}`);
      });

  };

  return (
    <Fragment>
      <HcareModal
        title={'Terms & Conditions - Candidate Rights: (tester)'}
        isOpen={isOpenTerms}
        closeModal={handle_OnClickCloseButton}
        submitbtn={acceptRenderSubmitBtn}
        // customStyle={{ maxWidth: "50%" }}
        className="terms-conditions"
        // enablebtn={false}
      >
        <AssessmentTermsConditions />
        <FormGroup className="form-check" style={{ marginTop: '2rem' }}>
          <Input type="checkbox" name="accept" id="accept" value={accept} onChange={acceptHandle_OnChange} />
          <Label for="accept" check>
            I agree to terms and conditions of escoreit software
          </Label>
        </FormGroup>
      </HcareModal>

      <ExamDialog
        visible={showExamDialog}
        setVisible={setExamDialogVisible}
        user={currentuser}
        exam={currentExam}
        backdrop={'static'}
      />
      {!isLoadedExamList && <Loader />}
      {isLoadedExamList && currentuser.roles[0] === 'user' && (
        <Card className="mb-3 table-container">
          <CardBody className="bg-light">
              <PageFilterableTable
                data={examList}
                columns={columns}
                keyField="clinicianAssessmentId"
                title={organizationName}
                enableNewButton={false}
                sizePerPage={8}
              />
          </CardBody>
        </Card>
      )}
    </Fragment>
  );
};
export default ExamList;
