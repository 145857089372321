import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, Label, FormFeedback, Row, Col, Alert } from 'reactstrap';
import RadioAnswer from "./radioAnswer";

const CheckListRadioQuestion = (props) => {
    const isInvalid = (questionid) => {
        if (Array.isArray(props.invalid)) {
            let found = props.invalid.find(invalidanswer => invalidanswer.answer === questionid);
            return !found.isValid;
        } else {
            return props.invalid;
        }
    }

    return (
        <FormGroup>
            <Row>
                <Col lg={3}>
                    <Label>
                        {props.question}
                    </Label>
                </Col>
                <Col lg={4} className="pl-2 pr-2">
                    <div className="d-inline">
                        {props.proposedanswers.map(
                            (proposedanswer) =>
                                <RadioAnswer
                                    name={"proficiencyanswerId_" + props.answerid}
                                    id={"proficiencyanswerId" + props.answerid + "_" + proposedanswer.validAnswerId}
                                    key={proposedanswer.validAnswerId}
                                    answerid={proposedanswer.validAnswerId}
                                    answer={proposedanswer.validAnswerText}
                                    inline={props.inline ? true : false}
                                    onChange={props.onChange}
                                    value={proposedanswer.validAnswerId}
                                    invalid={isInvalid("proficiencyanswerId_" + props.answerid)}
                                    checked={proposedanswer.validAnswerId === props.answer.proficiency}
                                />)}
                    </div>
                </Col>
                <Col lg={4} className="pl-2 pr-2">
                    <div className="d-inline">
                        {props.proposedanswers.map(
                            (proposedanswer) =>
                                <RadioAnswer
                                    name={"frequencyanswerId_" + props.answerid}
                                    id={"frequencyanswerId" + props.answerid + "_" + proposedanswer.validAnswerId}
                                    key={proposedanswer.validAnswerId}
                                    answerid={proposedanswer.validAnswerId}
                                    answer={proposedanswer.validAnswerText}
                                    inline={props.inline ? true : false}
                                    onChange={props.onChange}
                                    value={proposedanswer.validAnswerId}
                                    invalid={isInvalid("frequencyanswerId_" + props.answerid)}
                                    checked={proposedanswer.validAnswerId === props.answer.frequency}
                                />)}
                    </div>
                </Col>
            </Row>
        </FormGroup>
    )
}

const CheckListQuestion = (props) => {
    const staticproposedanswers = [
        {
            "validAnswerId": 1,
            "validAnswerText": "N/A"
        },
        {
            "validAnswerId": 2,
            "validAnswerText": "1"
        },
        {
            "validAnswerId": 3,
            "validAnswerText": "2"
        },
        {
            "validAnswerId": 4,
            "validAnswerText": "3"
        },
        {
            "validAnswerId": 5,
            "validAnswerText": "4"
        }
    ];


    return (
        <FormGroup>
            <Alert className='p-1 mb-1' color='success'>
                <Label lg={12}><strong>{props.question}</strong></Label>
            </Alert>
            <Row>
                <Col lg={3}>

                </Col>
                <Col lg={4} className="pl-2 pr-2">
                    <Label><strong>Proficiency</strong></Label>
                </Col>
                <Col lg={4} className="pl-2 pr-2">
                    <Label><strong>Frequency</strong></Label>
                </Col>
            </Row>
            <div>
                {props.proposedanswers.map(
                    (proposedanswer) =>
                        <CheckListRadioQuestion
                            id={"checklist" + proposedanswer.validAnswerId}
                            key={proposedanswer.validAnswerId}
                            answerid={proposedanswer.validAnswerId}
                            question={proposedanswer.validAnswerText}
                            proposedanswers={staticproposedanswers}
                            answer={proposedanswer}
                            onChange={props.onChange}
                            invalid={props.invalid}
                            inline={props.inline ? true : false}
                        />
                )}
            </div>
            {props.invalid && <FormFeedback style={{ display: "contents" }}>
                Please complete all questions!
            </FormFeedback>}
        </FormGroup>
    )
}

export default CheckListQuestion;

CheckListQuestion.defaultProps = {
    question: 'How many items are?',
    proposedanswers: [],
    inline: false,
    invalid: false
}

CheckListQuestion.propTypes = {
    question: PropTypes.string,
    proposedanswers: PropTypes.array,
    inline: PropTypes.bool,
    onChange: PropTypes.func,
    invalid: PropTypes.any
}
