import axios from 'axios';
import authHeader from '../auth-header';
import { API_URL } from "../../config";

const SPECIALITY_API_URL = API_URL + "/specialty";

class SpecialtyService {
    all() {
        return axios.get(SPECIALITY_API_URL, { headers: authHeader() });
    }
    create(specialty) {
        return axios
            .post(SPECIALITY_API_URL,
                specialty,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    delete(id) {
        return axios.delete(SPECIALITY_API_URL + `/${id}`, { headers: authHeader() });
    }
    update(specialty) {
        return axios
            .put(SPECIALITY_API_URL,
                specialty,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    find(id) {
        return axios.get(SPECIALITY_API_URL + `/${id}`, { headers: authHeader() });
    }
    assignBundles(bundles) {
        return axios
            .post(SPECIALITY_API_URL + '/assignbundles',
                bundles,
                { headers: authHeader() }
            )
            .then(response => {
                return response.data;
            });
    }
    statistic() {
        return axios.get(SPECIALITY_API_URL + '/graphic_prof_designation_clinicians', { headers: authHeader() });
    }
    specialtyExamsStatistic() {
        return axios.get(SPECIALITY_API_URL + '/graphic_prof_designation_exams', { headers: authHeader() });
    }
    // id: profDesignationTypeId value
    byProfDesignation(id) {
        return axios.get(SPECIALITY_API_URL + `/byprofdesignation/${id}`, { headers: authHeader() });
    }
    //return encrypted url generated from specialty id
    // id: specialty id value
    encrypt(id) {
        return axios.get(SPECIALITY_API_URL + `/encrypt/${id}`, { headers: authHeader() });
    }
    //return encrypted url generated from specialty id
    // id: organization id value
    graphicDeptLevelMetrics(id) {
        return axios.get(SPECIALITY_API_URL + `/graphic_dept_level_metrics/${id}`, { headers: authHeader() });
    }
}
export default new SpecialtyService();
