import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, Label, FormFeedback, CardImg } from 'reactstrap';
import RadioAnswer from "./radioAnswer";
import { beautyPipeline, beautyPipelineCeiling, removeChars } from "../../../helpers/hcutils";

const RadioQuestion = ({
  question,
  image,
  proposedanswers,
  onChange,
  invalid,
  answer,
  selectedExtendedDescription,
  selectedOtherDetail,
  inline
}) => {

  // Función para manejar el cambio en la selección del radio
  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = proposedanswers.find(option => option.validAnswerId === parseInt(selectedValue, 10));

    if (selectedOption) {
      onChange({
        selectedValue: selectedValue,
        selectedExtendedDescription: selectedOption.questionExtendedDescription || '',
        selectedOtherDetail: selectedOption.validOtherDetail || ''
      });
    } else {
      onChange({
        selectedValue: selectedValue,
        selectedExtendedDescription: '',
        selectedOtherDetail: ''
      });
    }
  };

  let formattedQuestion = removeChars(question);
  if (!formattedQuestion.includes('?|')) formattedQuestion = formattedQuestion.replace('?', '?\n\n');
  if (formattedQuestion.includes('|') && formattedQuestion.includes('^')) {
    formattedQuestion = beautyPipelineCeiling(formattedQuestion);
  }
  if (formattedQuestion.includes('|')) {
    formattedQuestion = beautyPipeline(formattedQuestion);
  }

  return (
    <FormGroup>
      {selectedExtendedDescription && (
        <>
          <div style={{ maxHeight: '4.5em', overflowY: 'auto', lineHeight: '1.5em', paddingRight: '10px' }}>
            <p className="mb-0">{selectedExtendedDescription}</p>
          </div>
          <hr style={{ margin: '1rem 0' }} />
        </>
      )}
      <Label for="selectOption" style={{ whiteSpace: 'pre-wrap' }}>
        {formattedQuestion}
      </Label>
      {image && (
        <figure className="figure d-block mx-auto" style={{ maxWidth: 'max-content' }}>
          <CardImg src={require(`../../../assets/img/questions/${image}.jpg`)} alt="Question image" />
        </figure>
      )}
      <div>
        {proposedanswers.map((proposedanswer) => (
          <RadioAnswer
            key={proposedanswer.validAnswerId}
            answerid={proposedanswer.validAnswerId}
            answer={proposedanswer.validAnswerText}
            inline={inline ? true : false}
            onChange={handleRadioChange}  // Llamamos a la función handleRadioChange
            value={proposedanswer.validAnswerId}
            invalid={invalid}
            checked={proposedanswer.validAnswerId === answer}
          />
        ))}
      </div>
      {invalid && <FormFeedback style={{ display: "contents" }}>
        Please select an answer!
      </FormFeedback>}
      {selectedOtherDetail && (
        <>
          <hr style={{ margin: '1rem 0' }} />
          <div style={{ maxHeight: '4.5em', overflowY: 'auto', lineHeight: '1.5em', paddingRight: '10px', marginTop: '1rem' }}>
            <p className="mb-0">{selectedOtherDetail}</p>
          </div>
        </>
      )}
    </FormGroup>
  );
}

export default RadioQuestion;

RadioQuestion.defaultProps = {
  question: 'How many items are?',
  proposedanswers: [],
  inline: false,
  invalid: false
}

RadioQuestion.propTypes = {
  question: PropTypes.string,
  proposedanswers: PropTypes.array,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  selectedExtendedDescription: PropTypes.string, // Asegúrate de incluir las nuevas props aquí
  selectedOtherDetail: PropTypes.string // Asegúrate de incluir las nuevas props aquí
}
