import React, { Fragment, useEffect, useState, useContext } from 'react';
import CountUp from 'react-countup';
import CardSummary from '../../../components/dashboard/CardSummary';
import { toast } from 'react-toastify';
import DashboardService from '../../../services/user/dashboard.service';
import AppContext from '../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Tooltip } from 'reactstrap';
import SpecialtyStatistics from '../specialty/specialtyStatistics';
import AssessmentStatistics from '../exam/AssessmentStatistics';
import organizationService from '../../../services/organization/organization.service';

const AdminCardSummary = (props) => {
    // State
    const { currentuser } = useContext(AppContext);
    const [isLoadedSummary, setIsLoadedSummary] = useState(false);
    const [userSummary, setUserSummary] = useState(null);

    const [isVisibleSpecialtyStatistics] = useState(true);
    const [isVisibleAssessmentStatistics] = useState(true);

    const [isOpenBottom, setIsOpenBottom] = useState(false);

    const getSummary = (id) => {
        Promise.all([DashboardService.adminSummary(id), organizationService.testers_completed_from_last_login(id)])
            .then(
                response => {
                    response[0].data.testers_completed_from_last_login = response[1].data;
                    setUserSummary(response[0].data);
                    setIsLoadedSummary(true);
                }
            )
            .catch(
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(`${resMessage}`);
                }
            );

    }

    useEffect(() => {
        getSummary(currentuser.id);
    }, [currentuser]);

    return (
        <Fragment>
            <div className="card-deck">
                <CardSummary
                    title="Total Employee Tested"
                    color="warning"
                >
                    <FontAwesomeIcon icon="users" transform="grow-2 up-12" className="text-success fa-pull-left outside" />

                    {isLoadedSummary ? userSummary.testedEmployee : 0}
                </CardSummary>
                <CardSummary
                    title="Total Assessment Pending"
                    color="warning"
                >
                    <FontAwesomeIcon icon={['fa', 'hourglass-half']} transform="grow-1 up-12" className="text-warning fa-pull-left outside" />

                    {isLoadedSummary ? userSummary.pendingAssessment : 0}
                </CardSummary>
                <CardSummary
                    title="Total Assessments Completed"
                    color="warning"
                >
                    <FontAwesomeIcon icon="file-alt" transform="grow-2 up-12" className="text-success fa-pull-left outside" />
                    {
                        (userSummary && userSummary.testers_completed_from_last_login > 0) &&
                        <Fragment>
                            <Button id="pop4" className="mr-2 button-outside-right remove-border-on-focus outside" type="button" color='transparent'>
                                <FontAwesomeIcon icon="asterisk" className="text-danger" />
                            </Button>
                            <Tooltip
                                placement="left"
                                isOpen={isOpenBottom}
                                target="pop4"
                                toggle={() => setIsOpenBottom(!isOpenBottom)}
                                container='.content'
                                className="asteriskTooltip"
                            >
                                {isLoadedSummary &&
                                    `${userSummary.testers_completed_from_last_login} new assessments were completed since your last login`
                                }
                            </Tooltip>
                        </Fragment>
                    }

                    <CountUp end={isLoadedSummary ? userSummary.completedAssessment : 0} duration={2} />
                </CardSummary>
            </div>

            <Row style={{ alignItems: 'center' }}>
                <Col lg={12}>
                    <SpecialtyStatistics isvisible={isVisibleSpecialtyStatistics} />
                </Col>
            </Row>
            <Row style={{ alignItems: 'center' }}>
                <Col lg={12}>
                    <AssessmentStatistics isvisible={isVisibleAssessmentStatistics} />
                </Col>
            </Row>
        </Fragment >
    );
};

export default AdminCardSummary;
