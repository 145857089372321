import React, { useContext } from 'react';
import PropTypes, { array, string } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';

import Avatar from '../common/Avatar';
import DefaultLogo from './DefaultLogo';
import LogoText from './LogoText';

const OrganizationLogo = ({ organizationLogo, width }) => {
  return <Avatar src={organizationLogo} rounded="soft" size="3xl" className="mr-2 align-middle" />;
};

OrganizationLogo.propTypes = {
  organizationLogo: PropTypes.oneOfType([array, string]),
  width: PropTypes.number.isRequired
};

const LogoRender = ({ currentuserdata, organizationLogo, isDark, width, at, isNavbarVerticalCollapsed }) => {
  if (at === 'auth') return <DefaultLogo isDark={isDark} width={width} />;
  if (!currentuserdata || !currentuserdata.department) return <DefaultLogo isDark={isDark} width={width} />;

  switch (currentuserdata.department.organization.logoTextAtr.toLowerCase().trim()) {
    case 'escore it':
    case '':
      return <DefaultLogo isDark={isDark} width={width} />;
    case 'logo':
      // Si tiene logo guardado mostrar el logo sino
      if (organizationLogo && organizationLogo[0].base64 && at !== 'navbar-vertical')
        return <OrganizationLogo organizationLogo={organizationLogo[0].base64} width={width} />;
      if (at !== 'navbar-vertical') return <DefaultLogo isDark={isDark} width={width} />;
      return !organizationLogo && at === 'navbar-vertical' ? <DefaultLogo isDark={isDark} width={width} /> : <></>;

    default:
      // Si gl_logo_text == 'logo someString'
      if (currentuserdata.department.organization.logoTextAtr.toLowerCase().includes('logo')) {
        if (at === 'navbar-top')
          return (
            <LogoText
              organizationName={currentuserdata.department.organization.logoTextAtr.replace('logo', '').trim()}
            />
          );
        else {
          if (isNavbarVerticalCollapsed) {
            return (
              <LogoText
                organizationName={currentuserdata.department.organization.logoTextAtr.replace('logo', '').trim()}
              />
            );
          } else {
            if (!organizationLogo)
              return (
                <LogoText
                  organizationName={currentuserdata.department.organization.logoTextAtr.replace('logo', '').trim()}
                />
              );
            return <></>;
          }
        }
      }

      if (currentuserdata.department.organization.logoTextAtr)
        return <LogoText organizationName={currentuserdata.department.organization.logoTextAtr.trim()} />;
  }

  return <DefaultLogo isDark={isDark} width={width} />;
};

LogoRender.propTypes = {
  currentuserdata: PropTypes.object,
  organizationLogo: PropTypes.oneOfType([array, string]),
  isDark: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired
};

const Logo = ({ at, width, className, currentuserdata, organizationLogo, isNavbarVerticalCollapsed, ...rest }) => {
  const { isDark } = useContext(AppContext);

  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-0 mb-0': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth',
            'flex-center font-weight-extra-bold fs-5': at === 'report'
          },
          className
        )}
      >
        {/* {!currentuserdata && !organizationLogo && <DefaultLogo isDark={isDark ? true : false} width={width} />} */}

        {/* {(currentuserdata || organizationLogo) && (
          <LogoRender
            currentuserdata={currentuserdata}
            organizationLogo={organizationLogo}
            isDark={isDark ? true : false}
            width={180}
            at={at}
          />
        )} */}

        {
          <LogoRender
            currentuserdata={currentuserdata}
            organizationLogo={organizationLogo}
            isDark={isDark ? true : false}
            width={180}
            at={at}
            isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          />
        }
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth', 'report']),
  width: PropTypes.number,
  className: PropTypes.string,
  currentuser: PropTypes.object
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
